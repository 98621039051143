import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/codebuild/output/src2870615501/src/kinetic-ui/docs/node_modules/gatsby-theme-docz/src/base/Layout.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "emptymessage"
    }}>{`EmptyMessage`}</h1>
    <p>{`Renders a generic message for the purposes of showing that a table or page has no data available to display. It
has the base class preset to `}<inlineCode parentName="p">{`empty-state`}</inlineCode>{` and a default title of "No results found."`}</p>
    <h2 {...{
      "id": "props"
    }}>{`Props`}</h2>
    <p><em parentName="p">{`See props for `}<a parentName="em" {...{
          "href": "./GenericMessageSupport#props"
        }}>{`Generic Message Support`}</a>{`.`}</em>{` All props are optional.`}</p>
    <h2 {...{
      "id": "example"
    }}>{`Example`}</h2>
    <pre><code parentName="pre" {...{
        "className": "language-javascript"
      }}>{`import { EmptyMessage } from '@kineticdata/bundle-common';

<EmptyMessage message="There are no items because there were none returned." />
`}</code></pre>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      